import { Map as ImmutableMap, Record as ImmutableRecord, fromJS } from 'immutable';
import { CardRecord, normalizeCard } from '../card';
export const AdRecord = ImmutableRecord({
  card: CardRecord(),
  impression: undefined,
  expires_at: undefined,
  reason: undefined
});
/** Normalizes an ad from Soapbox Config. */

export const normalizeAd = ad => {
  const map = ImmutableMap(fromJS(ad));
  const card = normalizeCard(map.get('card'));
  const expiresAt = map.get('expires_at') || map.get('expires');
  return AdRecord(map.merge({
    card,
    expires_at: expiresAt
  }));
};