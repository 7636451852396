import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
export const useOnScreen = ref => {
  const [isIntersecting, setIntersecting] = React.useState(false);
  const observer = new IntersectionObserver(_ref => {
    let [entry] = _ref;
    return setIntersecting(entry.isIntersecting);
  });
  React.useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);
  return isIntersecting;
};