import "core-js/modules/web.dom-collections.iterator.js";
import * as BuildConfig from 'soapbox/build-config';
export const start = () => {
  Promise.all([import(
  /* webpackChunkName: "error" */
  '@sentry/react'), import(
  /* webpackChunkName: "error" */
  '@sentry/tracing')]).then(_ref => {
    let [Sentry, {
      Integrations: Integrations
    }] = _ref;
    Sentry.init({
      dsn: BuildConfig.SENTRY_DSN,
      environment: BuildConfig.NODE_ENV,
      debug: false,
      integrations: [new Integrations.BrowserTracing()],
      // Filter events.
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/
      ignoreErrors: [// Network errors.
      'AxiosError', // sw.js couldn't be downloaded.
      'Failed to update a ServiceWorker for scope', // Useful for try/catch, useless as a Sentry error.
      'AbortError'],
      denyUrls: [// Browser extensions.
      /extensions\//i, /^chrome:\/\//i, /^moz-extension:\/\//i],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0
    });
  }).catch(console.error);
};
export const captureException = (exception, captureContext) => {
  import(
  /* webpackChunkName: "error" */
  '@sentry/react').then(Sentry => {
    Sentry.captureException(exception, captureContext);
  }).catch(console.error);
};