const _excluded = ["accounts"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import { Map as ImmutableMap, OrderedSet as ImmutableOrderedSet, Record as ImmutableRecord } from 'immutable';
import { FOLLOWERS_FETCH_SUCCESS, FOLLOWERS_EXPAND_SUCCESS, FOLLOWING_FETCH_SUCCESS, FOLLOWING_EXPAND_SUCCESS, FOLLOW_REQUESTS_FETCH_SUCCESS, FOLLOW_REQUESTS_EXPAND_SUCCESS, FOLLOW_REQUEST_AUTHORIZE_SUCCESS, FOLLOW_REQUEST_REJECT_SUCCESS, PINNED_ACCOUNTS_FETCH_SUCCESS, BIRTHDAY_REMINDERS_FETCH_SUCCESS } from '../actions/accounts';
import { BLOCKS_FETCH_SUCCESS, BLOCKS_EXPAND_SUCCESS } from '../actions/blocks';
import { DIRECTORY_FETCH_REQUEST, DIRECTORY_FETCH_SUCCESS, DIRECTORY_FETCH_FAIL, DIRECTORY_EXPAND_REQUEST, DIRECTORY_EXPAND_SUCCESS, DIRECTORY_EXPAND_FAIL } from '../actions/directory';
import { FAMILIAR_FOLLOWERS_FETCH_SUCCESS } from '../actions/familiar-followers';
import { REBLOGS_FETCH_SUCCESS, FAVOURITES_FETCH_SUCCESS, REACTIONS_FETCH_SUCCESS } from '../actions/interactions';
import { MUTES_FETCH_SUCCESS, MUTES_EXPAND_SUCCESS } from '../actions/mutes';
import { NOTIFICATIONS_UPDATE } from '../actions/notifications';
export const ListRecord = ImmutableRecord({
  next: null,
  items: ImmutableOrderedSet(),
  isLoading: false
});
export const ReactionRecord = ImmutableRecord({
  accounts: ImmutableOrderedSet(),
  count: 0,
  name: ''
});
const ReactionListRecord = ImmutableRecord({
  next: null,
  items: ImmutableOrderedSet(),
  isLoading: false
});
export const ReducerRecord = ImmutableRecord({
  followers: ImmutableMap(),
  following: ImmutableMap(),
  reblogged_by: ImmutableMap(),
  favourited_by: ImmutableMap(),
  reactions: ImmutableMap(),
  follow_requests: ListRecord(),
  blocks: ListRecord(),
  mutes: ListRecord(),
  directory: ListRecord({
    isLoading: true
  }),
  pinned: ImmutableMap(),
  birthday_reminders: ImmutableMap(),
  familiar_followers: ImmutableMap()
});

const normalizeList = (state, path, accounts, next) => {
  return state.setIn(path, ListRecord({
    next,
    items: ImmutableOrderedSet(accounts.map(item => item.id))
  }));
};

const appendToList = (state, path, accounts, next) => {
  return state.updateIn(path, map => {
    return map.set('next', next).set('isLoading', false).update('items', list => list.concat(accounts.map(item => item.id)));
  });
};

const removeFromList = (state, path, accountId) => {
  return state.updateIn(path, map => {
    return map.update('items', list => list.filterNot(item => item === accountId));
  });
};

const normalizeFollowRequest = (state, notification) => {
  return state.updateIn(['follow_requests', 'items'], list => {
    return ImmutableOrderedSet([notification.account.id]).union(list);
  });
};

export default function userLists() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReducerRecord();
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FOLLOWERS_FETCH_SUCCESS:
      return normalizeList(state, ['followers', action.id], action.accounts, action.next);

    case FOLLOWERS_EXPAND_SUCCESS:
      return appendToList(state, ['followers', action.id], action.accounts, action.next);

    case FOLLOWING_FETCH_SUCCESS:
      return normalizeList(state, ['following', action.id], action.accounts, action.next);

    case FOLLOWING_EXPAND_SUCCESS:
      return appendToList(state, ['following', action.id], action.accounts, action.next);

    case REBLOGS_FETCH_SUCCESS:
      return normalizeList(state, ['reblogged_by', action.id], action.accounts);

    case FAVOURITES_FETCH_SUCCESS:
      return normalizeList(state, ['favourited_by', action.id], action.accounts);

    case REACTIONS_FETCH_SUCCESS:
      return state.setIn(['reactions', action.id], ReactionListRecord({
        items: ImmutableOrderedSet(action.reactions.map(_ref => {
          let {
            accounts
          } = _ref,
              reaction = _objectWithoutProperties(_ref, _excluded);

          return ReactionRecord(_objectSpread(_objectSpread({}, reaction), {}, {
            accounts: ImmutableOrderedSet(accounts.map(account => account.id))
          }));
        }))
      }));

    case NOTIFICATIONS_UPDATE:
      return action.notification.type === 'follow_request' ? normalizeFollowRequest(state, action.notification) : state;

    case FOLLOW_REQUESTS_FETCH_SUCCESS:
      return normalizeList(state, ['follow_requests'], action.accounts, action.next);

    case FOLLOW_REQUESTS_EXPAND_SUCCESS:
      return appendToList(state, ['follow_requests'], action.accounts, action.next);

    case FOLLOW_REQUEST_AUTHORIZE_SUCCESS:
    case FOLLOW_REQUEST_REJECT_SUCCESS:
      return removeFromList(state, ['follow_requests'], action.id);

    case BLOCKS_FETCH_SUCCESS:
      return normalizeList(state, ['blocks'], action.accounts, action.next);

    case BLOCKS_EXPAND_SUCCESS:
      return appendToList(state, ['blocks'], action.accounts, action.next);

    case MUTES_FETCH_SUCCESS:
      return normalizeList(state, ['mutes'], action.accounts, action.next);

    case MUTES_EXPAND_SUCCESS:
      return appendToList(state, ['mutes'], action.accounts, action.next);

    case DIRECTORY_FETCH_SUCCESS:
      return normalizeList(state, ['directory'], action.accounts, action.next);

    case DIRECTORY_EXPAND_SUCCESS:
      return appendToList(state, ['directory'], action.accounts, action.next);

    case DIRECTORY_FETCH_REQUEST:
    case DIRECTORY_EXPAND_REQUEST:
      return state.setIn(['directory', 'isLoading'], true);

    case DIRECTORY_FETCH_FAIL:
    case DIRECTORY_EXPAND_FAIL:
      return state.setIn(['directory', 'isLoading'], false);

    case PINNED_ACCOUNTS_FETCH_SUCCESS:
      return normalizeList(state, ['pinned', action.id], action.accounts, action.next);

    case BIRTHDAY_REMINDERS_FETCH_SUCCESS:
      return normalizeList(state, ['birthday_reminders', action.id], action.accounts, action.next);

    case FAMILIAR_FOLLOWERS_FETCH_SUCCESS:
      return normalizeList(state, ['familiar_followers', action.id], action.accounts, action.next);

    default:
      return state;
  }
}