import "core-js/modules/es.string.replace.js";
import { OrderedSet as ImmutableOrderedSet } from 'immutable';

/** Convert a plain tag into a badge. */
const tagToBadge = tag => "badge:".concat(tag);
/** Convert a badge into a plain tag. */


const badgeToTag = badge => badge.replace(/^badge:/, '');
/** Difference between an old and new set of tags. */


/** Returns the differences between two sets of tags. */
const getTagDiff = (oldTags, newTags) => {
  const o = ImmutableOrderedSet(oldTags);
  const n = ImmutableOrderedSet(newTags);
  return {
    added: n.subtract(o).toArray(),
    removed: o.subtract(n).toArray()
  };
};
/** Returns only tags which are badges. */


const filterBadges = tags => {
  return tags.filter(tag => tag.startsWith('badge:'));
};
/** Get badges from an account. */


const getBadges = account => {
  const tags = Array.from((account === null || account === void 0 ? void 0 : account.getIn(['pleroma', 'tags'])) || []);
  return filterBadges(tags);
};

export { tagToBadge, badgeToTag, filterBadges, getTagDiff, getBadges };