import "core-js/modules/es.array.includes.js";

/** Notification types known to Soapbox. */
const NOTIFICATION_TYPES = ['follow', 'follow_request', 'mention', 'reblog', 'favourite', 'poll', 'status', 'move', 'pleroma:chat_mention', 'pleroma:emoji_reaction', 'user_approved', 'update'];
/** Notification types to exclude from the "All" filter by default. */

const EXCLUDE_TYPES = ['pleroma:chat_mention', 'chat' // TruthSocial
];

/** Ensure the Notification is a valid, known type. */
const validType = type => NOTIFICATION_TYPES.includes(type);

export { NOTIFICATION_TYPES, EXCLUDE_TYPES, validType };