import { OrderedSet as ImmutableOrderedSet, Record as ImmutableRecord } from 'immutable';
import { TRENDING_STATUSES_FETCH_REQUEST, TRENDING_STATUSES_FETCH_SUCCESS } from 'soapbox/actions/trending-statuses';
const ReducerRecord = ImmutableRecord({
  items: ImmutableOrderedSet(),
  isLoading: false
});

const toIds = items => ImmutableOrderedSet(items.map(item => item.id));

const importStatuses = (state, statuses) => {
  return state.withMutations(state => {
    state.set('items', toIds(statuses));
    state.set('isLoading', false);
  });
};

export default function trending_statuses() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReducerRecord();
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case TRENDING_STATUSES_FETCH_REQUEST:
      return state.set('isLoading', true);

    case TRENDING_STATUSES_FETCH_SUCCESS:
      return importStatuses(state, action.statuses);

    default:
      return state;
  }
}