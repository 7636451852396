const _excluded = ["data"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import { fetchRelationships } from 'soapbox/actions/accounts';
import { importFetchedAccount, importFetchedAccounts, importFetchedStatuses } from 'soapbox/actions/importer';
import { filterBadges, getTagDiff } from 'soapbox/utils/badges';
import { getFeatures } from 'soapbox/utils/features';
import api, { getLinks } from '../api';
const ADMIN_CONFIG_FETCH_REQUEST = 'ADMIN_CONFIG_FETCH_REQUEST';
const ADMIN_CONFIG_FETCH_SUCCESS = 'ADMIN_CONFIG_FETCH_SUCCESS';
const ADMIN_CONFIG_FETCH_FAIL = 'ADMIN_CONFIG_FETCH_FAIL';
const ADMIN_CONFIG_UPDATE_REQUEST = 'ADMIN_CONFIG_UPDATE_REQUEST';
const ADMIN_CONFIG_UPDATE_SUCCESS = 'ADMIN_CONFIG_UPDATE_SUCCESS';
const ADMIN_CONFIG_UPDATE_FAIL = 'ADMIN_CONFIG_UPDATE_FAIL';
const ADMIN_REPORTS_FETCH_REQUEST = 'ADMIN_REPORTS_FETCH_REQUEST';
const ADMIN_REPORTS_FETCH_SUCCESS = 'ADMIN_REPORTS_FETCH_SUCCESS';
const ADMIN_REPORTS_FETCH_FAIL = 'ADMIN_REPORTS_FETCH_FAIL';
const ADMIN_REPORTS_PATCH_REQUEST = 'ADMIN_REPORTS_PATCH_REQUEST';
const ADMIN_REPORTS_PATCH_SUCCESS = 'ADMIN_REPORTS_PATCH_SUCCESS';
const ADMIN_REPORTS_PATCH_FAIL = 'ADMIN_REPORTS_PATCH_FAIL';
const ADMIN_USERS_FETCH_REQUEST = 'ADMIN_USERS_FETCH_REQUEST';
const ADMIN_USERS_FETCH_SUCCESS = 'ADMIN_USERS_FETCH_SUCCESS';
const ADMIN_USERS_FETCH_FAIL = 'ADMIN_USERS_FETCH_FAIL';
const ADMIN_USERS_DELETE_REQUEST = 'ADMIN_USERS_DELETE_REQUEST';
const ADMIN_USERS_DELETE_SUCCESS = 'ADMIN_USERS_DELETE_SUCCESS';
const ADMIN_USERS_DELETE_FAIL = 'ADMIN_USERS_DELETE_FAIL';
const ADMIN_USERS_APPROVE_REQUEST = 'ADMIN_USERS_APPROVE_REQUEST';
const ADMIN_USERS_APPROVE_SUCCESS = 'ADMIN_USERS_APPROVE_SUCCESS';
const ADMIN_USERS_APPROVE_FAIL = 'ADMIN_USERS_APPROVE_FAIL';
const ADMIN_USERS_DEACTIVATE_REQUEST = 'ADMIN_USERS_DEACTIVATE_REQUEST';
const ADMIN_USERS_DEACTIVATE_SUCCESS = 'ADMIN_USERS_DEACTIVATE_SUCCESS';
const ADMIN_USERS_DEACTIVATE_FAIL = 'ADMIN_USERS_DEACTIVATE_FAIL';
const ADMIN_STATUS_DELETE_REQUEST = 'ADMIN_STATUS_DELETE_REQUEST';
const ADMIN_STATUS_DELETE_SUCCESS = 'ADMIN_STATUS_DELETE_SUCCESS';
const ADMIN_STATUS_DELETE_FAIL = 'ADMIN_STATUS_DELETE_FAIL';
const ADMIN_STATUS_TOGGLE_SENSITIVITY_REQUEST = 'ADMIN_STATUS_TOGGLE_SENSITIVITY_REQUEST';
const ADMIN_STATUS_TOGGLE_SENSITIVITY_SUCCESS = 'ADMIN_STATUS_TOGGLE_SENSITIVITY_SUCCESS';
const ADMIN_STATUS_TOGGLE_SENSITIVITY_FAIL = 'ADMIN_STATUS_TOGGLE_SENSITIVITY_FAIL';
const ADMIN_LOG_FETCH_REQUEST = 'ADMIN_LOG_FETCH_REQUEST';
const ADMIN_LOG_FETCH_SUCCESS = 'ADMIN_LOG_FETCH_SUCCESS';
const ADMIN_LOG_FETCH_FAIL = 'ADMIN_LOG_FETCH_FAIL';
const ADMIN_USERS_TAG_REQUEST = 'ADMIN_USERS_TAG_REQUEST';
const ADMIN_USERS_TAG_SUCCESS = 'ADMIN_USERS_TAG_SUCCESS';
const ADMIN_USERS_TAG_FAIL = 'ADMIN_USERS_TAG_FAIL';
const ADMIN_USERS_UNTAG_REQUEST = 'ADMIN_USERS_UNTAG_REQUEST';
const ADMIN_USERS_UNTAG_SUCCESS = 'ADMIN_USERS_UNTAG_SUCCESS';
const ADMIN_USERS_UNTAG_FAIL = 'ADMIN_USERS_UNTAG_FAIL';
const ADMIN_ADD_PERMISSION_GROUP_REQUEST = 'ADMIN_ADD_PERMISSION_GROUP_REQUEST';
const ADMIN_ADD_PERMISSION_GROUP_SUCCESS = 'ADMIN_ADD_PERMISSION_GROUP_SUCCESS';
const ADMIN_ADD_PERMISSION_GROUP_FAIL = 'ADMIN_ADD_PERMISSION_GROUP_FAIL';
const ADMIN_REMOVE_PERMISSION_GROUP_REQUEST = 'ADMIN_REMOVE_PERMISSION_GROUP_REQUEST';
const ADMIN_REMOVE_PERMISSION_GROUP_SUCCESS = 'ADMIN_REMOVE_PERMISSION_GROUP_SUCCESS';
const ADMIN_REMOVE_PERMISSION_GROUP_FAIL = 'ADMIN_REMOVE_PERMISSION_GROUP_FAIL';
const ADMIN_USERS_SUGGEST_REQUEST = 'ADMIN_USERS_SUGGEST_REQUEST';
const ADMIN_USERS_SUGGEST_SUCCESS = 'ADMIN_USERS_SUGGEST_SUCCESS';
const ADMIN_USERS_SUGGEST_FAIL = 'ADMIN_USERS_SUGGEST_FAIL';
const ADMIN_USERS_UNSUGGEST_REQUEST = 'ADMIN_USERS_UNSUGGEST_REQUEST';
const ADMIN_USERS_UNSUGGEST_SUCCESS = 'ADMIN_USERS_UNSUGGEST_SUCCESS';
const ADMIN_USERS_UNSUGGEST_FAIL = 'ADMIN_USERS_UNSUGGEST_FAIL';

const nicknamesFromIds = (getState, ids) => ids.map(id => getState().accounts.get(id).acct);

const fetchConfig = () => (dispatch, getState) => {
  dispatch({
    type: ADMIN_CONFIG_FETCH_REQUEST
  });
  return api(getState).get('/api/pleroma/admin/config').then(_ref => {
    let {
      data
    } = _ref;
    dispatch({
      type: ADMIN_CONFIG_FETCH_SUCCESS,
      configs: data.configs,
      needsReboot: data.need_reboot
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_CONFIG_FETCH_FAIL,
      error
    });
  });
};

const updateConfig = configs => (dispatch, getState) => {
  dispatch({
    type: ADMIN_CONFIG_UPDATE_REQUEST,
    configs
  });
  return api(getState).post('/api/pleroma/admin/config', {
    configs
  }).then(_ref2 => {
    let {
      data
    } = _ref2;
    dispatch({
      type: ADMIN_CONFIG_UPDATE_SUCCESS,
      configs: data.configs,
      needsReboot: data.need_reboot
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_CONFIG_UPDATE_FAIL,
      error,
      configs
    });
  });
};

const fetchMastodonReports = params => (dispatch, getState) => api(getState).get('/api/v1/admin/reports', {
  params
}).then(_ref3 => {
  let {
    data: reports
  } = _ref3;
  reports.forEach(report => {
    var _report$account, _report$target_accoun;

    dispatch(importFetchedAccount((_report$account = report.account) === null || _report$account === void 0 ? void 0 : _report$account.account));
    dispatch(importFetchedAccount((_report$target_accoun = report.target_account) === null || _report$target_accoun === void 0 ? void 0 : _report$target_accoun.account));
    dispatch(importFetchedStatuses(report.statuses));
  });
  dispatch({
    type: ADMIN_REPORTS_FETCH_SUCCESS,
    reports,
    params
  });
}).catch(error => {
  dispatch({
    type: ADMIN_REPORTS_FETCH_FAIL,
    error,
    params
  });
});

const fetchPleromaReports = params => (dispatch, getState) => api(getState).get('/api/pleroma/admin/reports', {
  params
}).then(_ref4 => {
  let {
    data: {
      reports
    }
  } = _ref4;
  reports.forEach(report => {
    dispatch(importFetchedAccount(report.account));
    dispatch(importFetchedAccount(report.actor));
    dispatch(importFetchedStatuses(report.statuses));
  });
  dispatch({
    type: ADMIN_REPORTS_FETCH_SUCCESS,
    reports,
    params
  });
}).catch(error => {
  dispatch({
    type: ADMIN_REPORTS_FETCH_FAIL,
    error,
    params
  });
});

const fetchReports = function () {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (dispatch, getState) => {
    const state = getState();
    const instance = state.instance;
    const features = getFeatures(instance);
    dispatch({
      type: ADMIN_REPORTS_FETCH_REQUEST,
      params
    });

    if (features.mastodonAdmin) {
      return dispatch(fetchMastodonReports(params));
    } else {
      const {
        resolved
      } = params;
      return dispatch(fetchPleromaReports({
        state: resolved === false ? 'open' : resolved ? 'resolved' : null
      }));
    }
  };
};

const patchMastodonReports = reports => (dispatch, getState) => Promise.all(reports.map(_ref5 => {
  let {
    id,
    state
  } = _ref5;
  return api(getState).post("/api/v1/admin/reports/".concat(id, "/").concat(state === 'resolved' ? 'reopen' : 'resolve')).then(() => {
    dispatch({
      type: ADMIN_REPORTS_PATCH_SUCCESS,
      reports
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_REPORTS_PATCH_FAIL,
      error,
      reports
    });
  });
}));

const patchPleromaReports = reports => (dispatch, getState) => api(getState).patch('/api/pleroma/admin/reports', {
  reports
}).then(() => {
  dispatch({
    type: ADMIN_REPORTS_PATCH_SUCCESS,
    reports
  });
}).catch(error => {
  dispatch({
    type: ADMIN_REPORTS_PATCH_FAIL,
    error,
    reports
  });
});

const patchReports = (ids, reportState) => (dispatch, getState) => {
  const state = getState();
  const instance = state.instance;
  const features = getFeatures(instance);
  const reports = ids.map(id => ({
    id,
    state: reportState
  }));
  dispatch({
    type: ADMIN_REPORTS_PATCH_REQUEST,
    reports
  });

  if (features.mastodonAdmin) {
    return dispatch(patchMastodonReports(reports));
  } else {
    return dispatch(patchPleromaReports(reports));
  }
};

const closeReports = ids => patchReports(ids, 'closed');

const fetchMastodonUsers = (filters, page, query, pageSize, next) => (dispatch, getState) => {
  const params = {
    username: query
  };
  if (filters.includes('local')) params.local = true;
  if (filters.includes('active')) params.active = true;
  if (filters.includes('need_approval')) params.pending = true;
  return api(getState).get(next || '/api/v1/admin/accounts', {
    params
  }).then(_ref6 => {
    let {
      data: accounts
    } = _ref6,
        response = _objectWithoutProperties(_ref6, _excluded);

    const next = getLinks(response).refs.find(link => link.rel === 'next');
    const count = next ? page * pageSize + 1 : (page - 1) * pageSize + accounts.length;
    dispatch(importFetchedAccounts(accounts.map(_ref7 => {
      let {
        account
      } = _ref7;
      return account;
    })));
    dispatch(fetchRelationships(accounts.map(account => account.id)));
    dispatch({
      type: ADMIN_USERS_FETCH_SUCCESS,
      users: accounts,
      count,
      pageSize,
      filters,
      page,
      next: (next === null || next === void 0 ? void 0 : next.uri) || false
    });
    return {
      users: accounts,
      count,
      pageSize,
      next: (next === null || next === void 0 ? void 0 : next.uri) || false
    };
  }).catch(error => dispatch({
    type: ADMIN_USERS_FETCH_FAIL,
    error,
    filters,
    page,
    pageSize
  }));
};

const fetchPleromaUsers = (filters, page, query, pageSize) => (dispatch, getState) => {
  const params = {
    filters: filters.join(),
    page,
    page_size: pageSize
  };
  if (query) params.query = query;
  return api(getState).get('/api/pleroma/admin/users', {
    params
  }).then(_ref8 => {
    let {
      data: {
        users,
        count,
        page_size: pageSize
      }
    } = _ref8;
    dispatch(fetchRelationships(users.map(user => user.id)));
    dispatch({
      type: ADMIN_USERS_FETCH_SUCCESS,
      users,
      count,
      pageSize,
      filters,
      page
    });
    return {
      users,
      count,
      pageSize
    };
  }).catch(error => dispatch({
    type: ADMIN_USERS_FETCH_FAIL,
    error,
    filters,
    page,
    pageSize
  }));
};

const fetchUsers = function () {
  let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  let query = arguments.length > 2 ? arguments[2] : undefined;
  let pageSize = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 50;
  let next = arguments.length > 4 ? arguments[4] : undefined;
  return (dispatch, getState) => {
    const state = getState();
    const instance = state.instance;
    const features = getFeatures(instance);
    dispatch({
      type: ADMIN_USERS_FETCH_REQUEST,
      filters,
      page,
      pageSize
    });

    if (features.mastodonAdmin) {
      return dispatch(fetchMastodonUsers(filters, page, query, pageSize, next));
    } else {
      return dispatch(fetchPleromaUsers(filters, page, query, pageSize));
    }
  };
};

const deactivateMastodonUsers = (accountIds, reportId) => (dispatch, getState) => Promise.all(accountIds.map(accountId => {
  api(getState).post("/api/v1/admin/accounts/".concat(accountId, "/action"), {
    type: 'disable',
    report_id: reportId
  }).then(() => {
    dispatch({
      type: ADMIN_USERS_DEACTIVATE_SUCCESS,
      accountIds: [accountId]
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_DEACTIVATE_FAIL,
      error,
      accountIds: [accountId]
    });
  });
}));

const deactivatePleromaUsers = accountIds => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  return api(getState).patch('/api/pleroma/admin/users/deactivate', {
    nicknames
  }).then(_ref9 => {
    let {
      data: {
        users
      }
    } = _ref9;
    dispatch({
      type: ADMIN_USERS_DEACTIVATE_SUCCESS,
      users,
      accountIds
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_DEACTIVATE_FAIL,
      error,
      accountIds
    });
  });
};

const deactivateUsers = (accountIds, reportId) => (dispatch, getState) => {
  const state = getState();
  const instance = state.instance;
  const features = getFeatures(instance);
  dispatch({
    type: ADMIN_USERS_DEACTIVATE_REQUEST,
    accountIds
  });

  if (features.mastodonAdmin) {
    return dispatch(deactivateMastodonUsers(accountIds, reportId));
  } else {
    return dispatch(deactivatePleromaUsers(accountIds));
  }
};

const deleteUsers = accountIds => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  dispatch({
    type: ADMIN_USERS_DELETE_REQUEST,
    accountIds
  });
  return api(getState).delete('/api/pleroma/admin/users', {
    data: {
      nicknames
    }
  }).then(_ref10 => {
    let {
      data: nicknames
    } = _ref10;
    dispatch({
      type: ADMIN_USERS_DELETE_SUCCESS,
      nicknames,
      accountIds
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_DELETE_FAIL,
      error,
      accountIds
    });
  });
};

const approveMastodonUsers = accountIds => (dispatch, getState) => Promise.all(accountIds.map(accountId => {
  api(getState).post("/api/v1/admin/accounts/".concat(accountId, "/approve")).then(_ref11 => {
    let {
      data: user
    } = _ref11;
    dispatch({
      type: ADMIN_USERS_APPROVE_SUCCESS,
      users: [user],
      accountIds: [accountId]
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_APPROVE_FAIL,
      error,
      accountIds: [accountId]
    });
  });
}));

const approvePleromaUsers = accountIds => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  return api(getState).patch('/api/pleroma/admin/users/approve', {
    nicknames
  }).then(_ref12 => {
    let {
      data: {
        users
      }
    } = _ref12;
    dispatch({
      type: ADMIN_USERS_APPROVE_SUCCESS,
      users,
      accountIds
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_APPROVE_FAIL,
      error,
      accountIds
    });
  });
};

const approveUsers = accountIds => (dispatch, getState) => {
  const state = getState();
  const instance = state.instance;
  const features = getFeatures(instance);
  dispatch({
    type: ADMIN_USERS_APPROVE_REQUEST,
    accountIds
  });

  if (features.mastodonAdmin) {
    return dispatch(approveMastodonUsers(accountIds));
  } else {
    return dispatch(approvePleromaUsers(accountIds));
  }
};

const deleteStatus = id => (dispatch, getState) => {
  dispatch({
    type: ADMIN_STATUS_DELETE_REQUEST,
    id
  });
  return api(getState).delete("/api/pleroma/admin/statuses/".concat(id)).then(() => {
    dispatch({
      type: ADMIN_STATUS_DELETE_SUCCESS,
      id
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_STATUS_DELETE_FAIL,
      error,
      id
    });
  });
};

const toggleStatusSensitivity = (id, sensitive) => (dispatch, getState) => {
  dispatch({
    type: ADMIN_STATUS_TOGGLE_SENSITIVITY_REQUEST,
    id
  });
  return api(getState).put("/api/pleroma/admin/statuses/".concat(id), {
    sensitive: !sensitive
  }).then(() => {
    dispatch({
      type: ADMIN_STATUS_TOGGLE_SENSITIVITY_SUCCESS,
      id
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_STATUS_TOGGLE_SENSITIVITY_FAIL,
      error,
      id
    });
  });
};

const fetchModerationLog = params => (dispatch, getState) => {
  dispatch({
    type: ADMIN_LOG_FETCH_REQUEST
  });
  return api(getState).get('/api/pleroma/admin/moderation_log', {
    params
  }).then(_ref13 => {
    let {
      data
    } = _ref13;
    dispatch({
      type: ADMIN_LOG_FETCH_SUCCESS,
      items: data.items,
      total: data.total
    });
    return data;
  }).catch(error => {
    dispatch({
      type: ADMIN_LOG_FETCH_FAIL,
      error
    });
  });
};

const tagUsers = (accountIds, tags) => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  dispatch({
    type: ADMIN_USERS_TAG_REQUEST,
    accountIds,
    tags
  });
  return api(getState).put('/api/v1/pleroma/admin/users/tag', {
    nicknames,
    tags
  }).then(() => {
    dispatch({
      type: ADMIN_USERS_TAG_SUCCESS,
      accountIds,
      tags
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_TAG_FAIL,
      error,
      accountIds,
      tags
    });
  });
};

const untagUsers = (accountIds, tags) => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds); // Legacy: allow removing legacy 'donor' tags.

  if (tags.includes('badge:donor')) {
    tags = [...tags, 'donor'];
  }

  dispatch({
    type: ADMIN_USERS_UNTAG_REQUEST,
    accountIds,
    tags
  });
  return api(getState).delete('/api/v1/pleroma/admin/users/tag', {
    data: {
      nicknames,
      tags
    }
  }).then(() => {
    dispatch({
      type: ADMIN_USERS_UNTAG_SUCCESS,
      accountIds,
      tags
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_UNTAG_FAIL,
      error,
      accountIds,
      tags
    });
  });
};
/** Synchronizes user tags to the backend. */


const setTags = (accountId, oldTags, newTags) => async dispatch => {
  const diff = getTagDiff(oldTags, newTags);
  await dispatch(tagUsers([accountId], diff.added));
  await dispatch(untagUsers([accountId], diff.removed));
};
/** Synchronizes badges to the backend. */


const setBadges = (accountId, oldTags, newTags) => dispatch => {
  const oldBadges = filterBadges(oldTags);
  const newBadges = filterBadges(newTags);
  return dispatch(setTags(accountId, oldBadges, newBadges));
};

const verifyUser = accountId => dispatch => dispatch(tagUsers([accountId], ['verified']));

const unverifyUser = accountId => dispatch => dispatch(untagUsers([accountId], ['verified']));

const addPermission = (accountIds, permissionGroup) => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  dispatch({
    type: ADMIN_ADD_PERMISSION_GROUP_REQUEST,
    accountIds,
    permissionGroup
  });
  return api(getState).post("/api/v1/pleroma/admin/users/permission_group/".concat(permissionGroup), {
    nicknames
  }).then(_ref14 => {
    let {
      data
    } = _ref14;
    dispatch({
      type: ADMIN_ADD_PERMISSION_GROUP_SUCCESS,
      accountIds,
      permissionGroup,
      data
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_ADD_PERMISSION_GROUP_FAIL,
      error,
      accountIds,
      permissionGroup
    });
  });
};

const removePermission = (accountIds, permissionGroup) => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  dispatch({
    type: ADMIN_REMOVE_PERMISSION_GROUP_REQUEST,
    accountIds,
    permissionGroup
  });
  return api(getState).delete("/api/v1/pleroma/admin/users/permission_group/".concat(permissionGroup), {
    data: {
      nicknames
    }
  }).then(_ref15 => {
    let {
      data
    } = _ref15;
    dispatch({
      type: ADMIN_REMOVE_PERMISSION_GROUP_SUCCESS,
      accountIds,
      permissionGroup,
      data
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_REMOVE_PERMISSION_GROUP_FAIL,
      error,
      accountIds,
      permissionGroup
    });
  });
};

const promoteToAdmin = accountId => dispatch => Promise.all([dispatch(addPermission([accountId], 'admin')), dispatch(removePermission([accountId], 'moderator'))]);

const promoteToModerator = accountId => dispatch => Promise.all([dispatch(removePermission([accountId], 'admin')), dispatch(addPermission([accountId], 'moderator'))]);

const demoteToUser = accountId => dispatch => Promise.all([dispatch(removePermission([accountId], 'admin')), dispatch(removePermission([accountId], 'moderator'))]);

const setRole = (accountId, role) => dispatch => {
  switch (role) {
    case 'user':
      return dispatch(demoteToUser(accountId));

    case 'moderator':
      return dispatch(promoteToModerator(accountId));

    case 'admin':
      return dispatch(promoteToAdmin(accountId));
  }
};

const suggestUsers = accountIds => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  dispatch({
    type: ADMIN_USERS_SUGGEST_REQUEST,
    accountIds
  });
  return api(getState).patch('/api/pleroma/admin/users/suggest', {
    nicknames
  }).then(_ref16 => {
    let {
      data: {
        users
      }
    } = _ref16;
    dispatch({
      type: ADMIN_USERS_SUGGEST_SUCCESS,
      users,
      accountIds
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_SUGGEST_FAIL,
      error,
      accountIds
    });
  });
};

const unsuggestUsers = accountIds => (dispatch, getState) => {
  const nicknames = nicknamesFromIds(getState, accountIds);
  dispatch({
    type: ADMIN_USERS_UNSUGGEST_REQUEST,
    accountIds
  });
  return api(getState).patch('/api/pleroma/admin/users/unsuggest', {
    nicknames
  }).then(_ref17 => {
    let {
      data: {
        users
      }
    } = _ref17;
    dispatch({
      type: ADMIN_USERS_UNSUGGEST_SUCCESS,
      users,
      accountIds
    });
  }).catch(error => {
    dispatch({
      type: ADMIN_USERS_UNSUGGEST_FAIL,
      error,
      accountIds
    });
  });
};

export { ADMIN_CONFIG_FETCH_REQUEST, ADMIN_CONFIG_FETCH_SUCCESS, ADMIN_CONFIG_FETCH_FAIL, ADMIN_CONFIG_UPDATE_REQUEST, ADMIN_CONFIG_UPDATE_SUCCESS, ADMIN_CONFIG_UPDATE_FAIL, ADMIN_REPORTS_FETCH_REQUEST, ADMIN_REPORTS_FETCH_SUCCESS, ADMIN_REPORTS_FETCH_FAIL, ADMIN_REPORTS_PATCH_REQUEST, ADMIN_REPORTS_PATCH_SUCCESS, ADMIN_REPORTS_PATCH_FAIL, ADMIN_USERS_FETCH_REQUEST, ADMIN_USERS_FETCH_SUCCESS, ADMIN_USERS_FETCH_FAIL, ADMIN_USERS_DELETE_REQUEST, ADMIN_USERS_DELETE_SUCCESS, ADMIN_USERS_DELETE_FAIL, ADMIN_USERS_APPROVE_REQUEST, ADMIN_USERS_APPROVE_SUCCESS, ADMIN_USERS_APPROVE_FAIL, ADMIN_USERS_DEACTIVATE_REQUEST, ADMIN_USERS_DEACTIVATE_SUCCESS, ADMIN_USERS_DEACTIVATE_FAIL, ADMIN_STATUS_DELETE_REQUEST, ADMIN_STATUS_DELETE_SUCCESS, ADMIN_STATUS_DELETE_FAIL, ADMIN_STATUS_TOGGLE_SENSITIVITY_REQUEST, ADMIN_STATUS_TOGGLE_SENSITIVITY_SUCCESS, ADMIN_STATUS_TOGGLE_SENSITIVITY_FAIL, ADMIN_LOG_FETCH_REQUEST, ADMIN_LOG_FETCH_SUCCESS, ADMIN_LOG_FETCH_FAIL, ADMIN_USERS_TAG_REQUEST, ADMIN_USERS_TAG_SUCCESS, ADMIN_USERS_TAG_FAIL, ADMIN_USERS_UNTAG_REQUEST, ADMIN_USERS_UNTAG_SUCCESS, ADMIN_USERS_UNTAG_FAIL, ADMIN_ADD_PERMISSION_GROUP_REQUEST, ADMIN_ADD_PERMISSION_GROUP_SUCCESS, ADMIN_ADD_PERMISSION_GROUP_FAIL, ADMIN_REMOVE_PERMISSION_GROUP_REQUEST, ADMIN_REMOVE_PERMISSION_GROUP_SUCCESS, ADMIN_REMOVE_PERMISSION_GROUP_FAIL, ADMIN_USERS_SUGGEST_REQUEST, ADMIN_USERS_SUGGEST_SUCCESS, ADMIN_USERS_SUGGEST_FAIL, ADMIN_USERS_UNSUGGEST_REQUEST, ADMIN_USERS_UNSUGGEST_SUCCESS, ADMIN_USERS_UNSUGGEST_FAIL, fetchConfig, updateConfig, fetchReports, closeReports, fetchUsers, deactivateUsers, deleteUsers, approveUsers, deleteStatus, toggleStatusSensitivity, fetchModerationLog, tagUsers, untagUsers, setTags, setBadges, verifyUser, unverifyUser, addPermission, removePermission, promoteToAdmin, promoteToModerator, demoteToUser, setRole, suggestUsers, unsuggestUsers };