import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";

/**
 * External Auth: workflow for logging in to remote servers.
 * @module soapbox/actions/external_auth
 * @see module:soapbox/actions/auth
 * @see module:soapbox/actions/apps
 * @see module:soapbox/actions/oauth
 */
import { createApp } from 'soapbox/actions/apps';
import { authLoggedIn, verifyCredentials, switchAccount } from 'soapbox/actions/auth';
import { obtainOAuthToken } from 'soapbox/actions/oauth';
import { normalizeInstance } from 'soapbox/normalizers';
import { parseBaseURL } from 'soapbox/utils/auth';
import sourceCode from 'soapbox/utils/code';
import { getWalletAndSign } from 'soapbox/utils/ethereum';
import { getFeatures } from 'soapbox/utils/features';
import { getQuirks } from 'soapbox/utils/quirks';
import { baseClient } from '../api';

const fetchExternalInstance = baseURL => {
  return baseClient(null, baseURL).get('/api/v1/instance').then(_ref => {
    let {
      data: instance
    } = _ref;
    return normalizeInstance(instance);
  }).catch(error => {
    var _error$response;

    if (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401) {
      // Authenticated fetch is enabled.
      // Continue with a limited featureset.
      return normalizeInstance({});
    } else {
      throw error;
    }
  });
};

const createExternalApp = (instance, baseURL) => dispatch => {
  // Mitra: skip creating the auth app
  if (getQuirks(instance).noApps) return new Promise(f => f({}));
  const {
    scopes
  } = getFeatures(instance);
  const params = {
    client_name: sourceCode.displayName,
    redirect_uris: "".concat(window.location.origin, "/login/external"),
    website: sourceCode.homepage,
    scopes
  };
  return dispatch(createApp(params, baseURL));
};

const externalAuthorize = (instance, baseURL) => dispatch => {
  const {
    scopes
  } = getFeatures(instance);
  return dispatch(createExternalApp(instance, baseURL)).then(app => {
    const {
      client_id,
      redirect_uri
    } = app;
    const query = new URLSearchParams({
      client_id,
      redirect_uri,
      response_type: 'code',
      scope: scopes
    });
    localStorage.setItem('soapbox:external:app', JSON.stringify(app));
    localStorage.setItem('soapbox:external:baseurl', baseURL);
    localStorage.setItem('soapbox:external:scopes', scopes);
    window.location.href = "".concat(baseURL, "/oauth/authorize?").concat(query.toString());
  });
};

const externalEthereumLogin = (instance, baseURL) => dispatch => {
  const loginMessage = instance.login_message;
  return getWalletAndSign(loginMessage).then(_ref2 => {
    let {
      wallet,
      signature
    } = _ref2;
    return dispatch(createExternalApp(instance, baseURL)).then(app => {
      const {
        client_id,
        client_secret
      } = app;
      const params = {
        grant_type: 'ethereum',
        wallet_address: wallet.toLowerCase(),
        client_id: client_id,
        client_secret: client_secret,
        password: signature,
        redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
        scope: getFeatures(instance).scopes
      };
      return dispatch(obtainOAuthToken(params, baseURL)).then(token => dispatch(authLoggedIn(token))).then(_ref3 => {
        let {
          access_token
        } = _ref3;
        return dispatch(verifyCredentials(access_token, baseURL));
      }).then(account => dispatch(switchAccount(account.id))).then(() => window.location.href = '/');
    });
  });
};

export const externalLogin = host => dispatch => {
  const baseURL = parseBaseURL(host) || parseBaseURL("https://".concat(host));
  return fetchExternalInstance(baseURL).then(instance => {
    const features = getFeatures(instance);
    const quirks = getQuirks(instance);

    if (features.ethereumLogin && quirks.noOAuthForm) {
      dispatch(externalEthereumLogin(instance, baseURL));
    } else {
      dispatch(externalAuthorize(instance, baseURL));
    }
  });
};
export const loginWithCode = code => dispatch => {
  const {
    client_id,
    client_secret,
    redirect_uri
  } = JSON.parse(localStorage.getItem('soapbox:external:app'));
  const baseURL = localStorage.getItem('soapbox:external:baseurl');
  const scope = localStorage.getItem('soapbox:external:scopes');
  const params = {
    client_id,
    client_secret,
    redirect_uri,
    grant_type: 'authorization_code',
    scope,
    code
  };
  return dispatch(obtainOAuthToken(params, baseURL)).then(token => dispatch(authLoggedIn(token))).then(_ref4 => {
    let {
      access_token
    } = _ref4;
    return dispatch(verifyCredentials(access_token, baseURL));
  }).then(account => dispatch(switchAccount(account.id))).then(() => window.location.href = '/');
};