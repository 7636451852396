import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import axios from 'axios';
import * as BuildConfig from 'soapbox/build-config';
import { isURL } from 'soapbox/utils/auth';
import sourceCode from 'soapbox/utils/code';
import { getFeatures } from 'soapbox/utils/features';
import { createApp } from './apps';

const createProviderApp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      scopes
    } = getFeatures(state.instance);
    const params = {
      client_name: sourceCode.displayName,
      redirect_uris: "".concat(window.location.origin, "/login/external"),
      website: sourceCode.homepage,
      scopes
    };
    return dispatch(createApp(params));
  };
};

export const prepareRequest = provider => {
  return async (dispatch, getState) => {
    const baseURL = isURL(BuildConfig.BACKEND_URL) ? BuildConfig.BACKEND_URL : '';
    const state = getState();
    const {
      scopes
    } = getFeatures(state.instance);
    const app = await dispatch(createProviderApp());
    const {
      client_id,
      redirect_uri
    } = app;
    localStorage.setItem('soapbox:external:app', JSON.stringify(app));
    localStorage.setItem('soapbox:external:baseurl', baseURL);
    localStorage.setItem('soapbox:external:scopes', scopes);
    const params = {
      provider,
      authorization: {
        client_id,
        redirect_uri,
        scope: scopes
      }
    };
    const formdata = axios.toFormData(params);
    const query = new URLSearchParams(formdata);
    location.href = "".concat(baseURL, "/oauth/prepare_request?").concat(query.toString());
  };
};