import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { Map as ImmutableMap, List as ImmutableList, fromJS } from 'immutable';
import { ADMIN_CONFIG_UPDATE_REQUEST, ADMIN_CONFIG_UPDATE_SUCCESS } from 'soapbox/actions/admin';
import { PLEROMA_PRELOAD_IMPORT } from 'soapbox/actions/preload';
import { normalizeInstance } from 'soapbox/normalizers/instance';
import KVStore from 'soapbox/storage/kv-store';
import { ConfigDB } from 'soapbox/utils/config-db';
import { rememberInstance, fetchInstance, fetchNodeinfo } from '../actions/instance';
const initialState = normalizeInstance(ImmutableMap());

const nodeinfoToInstance = nodeinfo => {
  // Match Pleroma's develop branch
  return normalizeInstance(ImmutableMap({
    pleroma: ImmutableMap({
      metadata: ImmutableMap({
        account_activation_required: nodeinfo.getIn(['metadata', 'accountActivationRequired']),
        features: nodeinfo.getIn(['metadata', 'features']),
        federation: nodeinfo.getIn(['metadata', 'federation']),
        fields_limits: ImmutableMap({
          max_fields: nodeinfo.getIn(['metadata', 'fieldsLimits', 'maxFields'])
        })
      })
    })
  }));
};

const importInstance = (_state, instance) => {
  return normalizeInstance(instance);
};

const importNodeinfo = (state, nodeinfo) => {
  return nodeinfoToInstance(nodeinfo).mergeDeep(state);
};

const preloadImport = (state, action, path) => {
  const instance = action.data[path];
  return instance ? importInstance(state, ImmutableMap(fromJS(instance))) : state;
};

const getConfigValue = (instanceConfig, key) => {
  const v = instanceConfig.find(value => value.getIn(['tuple', 0]) === key);
  return v ? v.getIn(['tuple', 1]) : undefined;
};

const importConfigs = (state, configs) => {
  // FIXME: This is pretty hacked together. Need to make a cleaner map.
  const config = ConfigDB.find(configs, ':pleroma', ':instance');
  const simplePolicy = ConfigDB.toSimplePolicy(configs);
  if (!config && !simplePolicy) return state;
  return state.withMutations(state => {
    if (config) {
      const value = config.get('value', ImmutableList());
      const registrationsOpen = getConfigValue(value, ':registrations_open');
      const approvalRequired = getConfigValue(value, ':account_approval_required');
      state.update('registrations', c => typeof registrationsOpen === 'boolean' ? registrationsOpen : c);
      state.update('approval_required', c => typeof approvalRequired === 'boolean' ? approvalRequired : c);
    }

    if (simplePolicy) {
      state.setIn(['pleroma', 'metadata', 'federation', 'mrf_simple'], simplePolicy);
    }
  });
};

const handleAuthFetch = state => {
  // Authenticated fetch is enabled, so make the instance appear censored
  return state.mergeWith((o, n) => o || n, {
    title: '██████',
    description: '████████████'
  });
};

const getHost = instance => {
  try {
    return new URL(instance.uri).host;
  } catch {
    try {
      return new URL("https://".concat(instance.uri)).host;
    } catch {
      return null;
    }
  }
};

const persistInstance = instance => {
  const host = getHost(instance);

  if (host) {
    KVStore.setItem("instance:".concat(host), instance).catch(console.error);
  }
};

const handleInstanceFetchFail = (state, error) => {
  var _error$response;

  if (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401) {
    return handleAuthFetch(state);
  } else {
    return state;
  }
};

export default function instance() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case PLEROMA_PRELOAD_IMPORT:
      return preloadImport(state, action, '/api/v1/instance');

    case rememberInstance.fulfilled.type:
      return importInstance(state, ImmutableMap(fromJS(action.payload)));

    case fetchInstance.fulfilled.type:
      persistInstance(action.payload);
      return importInstance(state, ImmutableMap(fromJS(action.payload)));

    case fetchInstance.rejected.type:
      return handleInstanceFetchFail(state, action.error);

    case fetchNodeinfo.fulfilled.type:
      return importNodeinfo(state, ImmutableMap(fromJS(action.payload)));

    case ADMIN_CONFIG_UPDATE_REQUEST:
    case ADMIN_CONFIG_UPDATE_SUCCESS:
      return importConfigs(state, ImmutableList(fromJS(action.configs)));

    default:
      return state;
  }
}