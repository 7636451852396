import "core-js/modules/es.array.includes.js";
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
// https://emojipedia.org/facebook
// I've customized them.
export const ALLOWED_EMOJI = ImmutableList(['👍', '❤️', '😆', '😮', '😢', '😩']);
export const sortEmoji = emojiReacts => emojiReacts.sortBy(emojiReact => -emojiReact.get('count'));
export const mergeEmoji = emojiReacts => emojiReacts // TODO: Merge similar emoji
;
export const mergeEmojiFavourites = function () {
  let emojiReacts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ImmutableList();
  let favouritesCount = arguments.length > 1 ? arguments[1] : undefined;
  let favourited = arguments.length > 2 ? arguments[2] : undefined;
  if (!favouritesCount) return emojiReacts;
  const likeIndex = emojiReacts.findIndex(emojiReact => emojiReact.get('name') === '👍');

  if (likeIndex > -1) {
    const likeCount = Number(emojiReacts.getIn([likeIndex, 'count']));
    favourited = favourited || Boolean(emojiReacts.getIn([likeIndex, 'me'], false));
    return emojiReacts.setIn([likeIndex, 'count'], likeCount + favouritesCount).setIn([likeIndex, 'me'], favourited);
  } else {
    return emojiReacts.push(ImmutableMap({
      count: favouritesCount,
      me: favourited,
      name: '👍'
    }));
  }
};

const hasMultiReactions = (emojiReacts, account) => emojiReacts.filter(e => e.get('accounts').filter(a => a.get('id') === account.get('id')).count() > 0).count() > 1;

const inAccounts = (accounts, id) => accounts.filter(a => a.get('id') === id).count() > 0;

export const oneEmojiPerAccount = (emojiReacts, me) => {
  emojiReacts = emojiReacts.reverse();
  return emojiReacts.reduce((acc, cur, idx) => {
    const accounts = cur.get('accounts', ImmutableList()).filter(a => !hasMultiReactions(acc, a));
    return acc.set(idx, cur.merge({
      accounts: accounts,
      count: accounts.count(),
      me: me ? inAccounts(accounts, me) : false
    }));
  }, emojiReacts).filter(e => e.get('count') > 0).reverse();
};
export const filterEmoji = function (emojiReacts) {
  let allowedEmoji = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ALLOWED_EMOJI;
  return emojiReacts.filter(emojiReact => allowedEmoji.includes(emojiReact.get('name')));
};
export const reduceEmoji = function (emojiReacts, favouritesCount, favourited) {
  let allowedEmoji = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ALLOWED_EMOJI;
  return filterEmoji(sortEmoji(mergeEmoji(mergeEmojiFavourites(emojiReacts, favouritesCount, favourited))), allowedEmoji);
};
export const getReactForStatus = function (status) {
  let allowedEmoji = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ALLOWED_EMOJI;
  const result = reduceEmoji(status.pleroma.get('emoji_reactions', ImmutableList()), status.favourites_count || 0, status.favourited, allowedEmoji).filter(e => e.get('me') === true).getIn([0, 'name']);
  return typeof result === 'string' ? result : undefined;
};
export const simulateEmojiReact = (emojiReacts, emoji) => {
  const idx = emojiReacts.findIndex(e => e.get('name') === emoji);
  const emojiReact = emojiReacts.get(idx);

  if (idx > -1 && emojiReact) {
    return emojiReacts.set(idx, emojiReact.merge({
      count: emojiReact.get('count') + 1,
      me: true
    }));
  } else {
    return emojiReacts.push(ImmutableMap({
      count: 1,
      me: true,
      name: emoji
    }));
  }
};
export const simulateUnEmojiReact = (emojiReacts, emoji) => {
  const idx = emojiReacts.findIndex(e => e.get('name') === emoji && e.get('me') === true);
  const emojiReact = emojiReacts.get(idx);

  if (emojiReact) {
    const newCount = emojiReact.get('count') - 1;
    if (newCount < 1) return emojiReacts.delete(idx);
    return emojiReacts.set(idx, emojiReact.merge({
      count: emojiReact.get('count') - 1,
      me: false
    }));
  } else {
    return emojiReacts;
  }
};