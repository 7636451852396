/** ID of this iframe (given by embed.js) when embedded on a page. */
let iframeId;
/** Receive iframe messages. */
// https://github.com/mastodon/mastodon/pull/4853

const handleMessage = e => {
  var _e$data;

  if (((_e$data = e.data) === null || _e$data === void 0 ? void 0 : _e$data.type) === 'setHeight') {
    var _e$data2;

    iframeId = (_e$data2 = e.data) === null || _e$data2 === void 0 ? void 0 : _e$data2.id;
  }
};

window.addEventListener('message', handleMessage);
export { iframeId };