import { List as ImmutableList, Map as ImmutableMap, Record as ImmutableRecord, fromJS } from 'immutable';
import { normalizeAttachment } from 'soapbox/normalizers/attachment';
export const ChatMessageRecord = ImmutableRecord({
  account_id: '',
  attachment: null,
  card: null,
  chat_id: '',
  content: '',
  created_at: new Date(),
  emojis: ImmutableList(),
  id: '',
  unread: false,
  deleting: false,
  pending: false
});

const normalizeMedia = status => {
  const attachment = status.get('attachment');

  if (attachment) {
    return status.set('attachment', normalizeAttachment(attachment));
  } else {
    return status;
  }
};

export const normalizeChatMessage = chatMessage => {
  return ChatMessageRecord(ImmutableMap(fromJS(chatMessage)).withMutations(chatMessage => {
    normalizeMedia(chatMessage);
  }));
};