import { useRef, useEffect } from 'react';
/** Get the last version of this value. */
// https://usehooks.com/usePrevious/

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};