import { connect } from 'react-redux';
import { cancelReplyCompose } from 'soapbox/actions/compose';
import { makeGetStatus } from 'soapbox/selectors';
import ReplyIndicator from '../components/reply-indicator';

const makeMapStateToProps = () => {
  const getStatus = makeGetStatus();

  const mapStateToProps = (state, _ref) => {
    var _state$compose$get, _state$compose$get2;

    let {
      composeId
    } = _ref;
    const statusId = (_state$compose$get = state.compose.get(composeId)) === null || _state$compose$get === void 0 ? void 0 : _state$compose$get.in_reply_to;
    const editing = !!((_state$compose$get2 = state.compose.get(composeId)) !== null && _state$compose$get2 !== void 0 && _state$compose$get2.id);
    return {
      status: getStatus(state, {
        id: statusId
      }),
      hideActions: editing
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(cancelReplyCompose());
  }

});

export default connect(makeMapStateToProps, mapDispatchToProps)(ReplyIndicator);