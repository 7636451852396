import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.replace.js";
import { processHtml } from './tiny-post-html-processor';
export const addGreentext = html => {
  // Copied from Pleroma FE
  // https://git.pleroma.social/pleroma/pleroma-fe/-/blob/19475ba356c3fd6c54ca0306d3ae392358c212d1/src/components/status_content/status_content.js#L132
  return processHtml(html, string => {
    try {
      if (string.includes('&gt;') && string.replace(/<[^>]+?>/gi, '') // remove all tags
      .replace(/@\w+/gi, '') // remove mentions (even failed ones)
      .trim().startsWith('&gt;')) {
        return "<span class='text-greentext'>".concat(string, "</span>");
      } else {
        return string;
      }
    } catch (e) {
      return string;
    }
  });
};