import { connect } from 'react-redux';
import { uploadCompose } from 'soapbox/actions/compose';
import UploadButton from '../components/upload-button';

const mapStateToProps = (state, _ref) => {
  var _state$compose$get, _state$compose$get2;

  let {
    composeId
  } = _ref;
  return {
    disabled: (_state$compose$get = state.compose.get(composeId)) === null || _state$compose$get === void 0 ? void 0 : _state$compose$get.is_uploading,
    resetFileKey: (_state$compose$get2 = state.compose.get(composeId)) === null || _state$compose$get2 === void 0 ? void 0 : _state$compose$get2.resetFileKey
  };
};

const mapDispatchToProps = (dispatch, _ref2) => {
  let {
    composeId
  } = _ref2;
  return {
    onSelectFile(files, intl) {
      dispatch(uploadCompose(composeId, files, intl));
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadButton);